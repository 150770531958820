<template>
  <div class="container" style="width: 90%">
    <h1 class="text-center">
      Please Enter One-Time Password to verify your Account
    </h1>
    <span class="d-flex justify-content-center"
      >A One-Time Password has been sent to {{ this.email }}</span
    >
    <div class="container">
      <div class="verify-otp">
        <div v-for="(item, index) in inputVals" :key="index" class="otp-box">
          <input
            type="text"
            :ref="`inputOtp`"
            :disabled="inputVals[index + 1] && inputVals[index + 1]?.val !== ''"
            v-model="item.val"
            @keydown="handleChange($event, item, index)"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <v-btn
          :disabled="disabled"
          rounded
          class="btn btn-success mr-3 mb-9 mt-5"
          style="color: #ffff; height: 60px; padding: 2rem 3rem"
          @click="validate()"
        >
          Vaildate
          <span v-if="isLogging">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span>
        </v-btn>
      </div>
      <div class="d-flex justify-content-center login-here">
        <router-link to="/login">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      email: this.$route.params.text,
      disabled: true,
      isLogging: false,
      deviceId: "",
      inputVals: [
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
        { val: "" },
      ],
    };
  },
  mounted() {
    fetch("https://geolocation-db.com/json/")
      .then((res) => {
        

        return res.json();
      })
      .then((res) => {
        this.deviceId = res.IPv4;
      });
  },
  computed: {
    endValue() {
      return this.inputVals.map((x) => x.val).join("");
    },
  },
  destroyed() {},
  methods: {
    validate() {
      this.isLogging = true;
      this.$store
        .dispatch("VerifyOtp", {
          Email: this.email,
          OTP: this.endValue,
          DeviceIp: this.deviceId,
        })
        .then((response) => {
          if (response.status) {
            this.$router.push({
              name: "dashboard",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.isLogging = false;
        });
    },
    handleChange(e, item, index) {
      if (e.key === "Backspace") {
        if (item.val !== "") {
          item.val = "";
          return;
        }
        if (index > 0) {
          this.$refs.inputOtp[index - 1].focus();
        }
        return;
      }
      e.preventDefault();
      const value = e.key;

      if (!isNaN(value) && e.key !== " ") {
        const emptyIndex = this.inputVals.findIndex(
          (input) => input.val === ""
        );
        const selectedIndex =
          emptyIndex < this.inputVals.length - 1
            ? emptyIndex + 1
            : this.inputVals.length - 1;
        if (emptyIndex !== -1) {
          const emptyItem = this.inputVals[emptyIndex];
          emptyItem.val = value;
          this.$refs.inputOtp[selectedIndex].focus();
          if (emptyIndex === this.inputVals.length - 1) {
            this.disabled = false;
          }
          return;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.verify-otp {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;

  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    outline: none;
  }

  .otp-box {
    font-size: 2rem;
    width: 5rem;
    height: 5rem;
    border: 1px solid gray;
  }
}
</style>
